import { useNavigation } from "@react-navigation/native";
import React, { Component, useState, useCallback } from "react";
import {
  Platform,
  StatusBar,
  SafeAreaView,
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  AsyncStorage,
} from "react-native";

import { useAuth } from "../hooks/useAuth";

import { HeaderText, BodyText } from "../components/Text";

import FFAButton from "../components/FFAButton";
import Colors from "../constants/Colors";
import useAddress from "../hooks/useAddress";
import i18n from 'i18n-js';
import Icon from "../components/Icon";
import IconName from "../constants/IconName";
import { DefaultTheme } from "../theme/DefaultTheme";
import Header from "../components/Header";

const AccountScreen = (props) => {
  const { currentUser } = useAuth();
  const { apiCurrentUser } = useAuth();
  const {navigate} = useNavigation();

  return (
    <SafeAreaView style={DefaultTheme.safeAreaContainer}>
      {currentUser ? <AuthenticatedView /> : navigate('login')}
    </SafeAreaView>
  );
};

const Avatar = () => {
  const { profileImage } = useAuth();

  return (
    <Image
      source={{
        uri: profileImage ? profileImage :
          "https://avatars3.githubusercontent.com/u/44938931?s=460&u=d386991175dac33d37cfef5ada23e67d643469e4&v=4",
      }}
      style={styles.avatar}
    />
  );
};

const Menu = ({ title, icon,iconName, onTap }) => {
  return (
    <TouchableOpacity onPress={onTap} style={styles.menuContainer}>
      <BodyText>{title}</BodyText>
      <Icon name={iconName} />
      {/* <Image source={icon} style={styles.icon} /> */}
    </TouchableOpacity>
  );
};

const AuthenticatedView = (props) => {
  const { navigate } = useNavigation();
  const { signOut } = useAuth();
  const { apiCurrentUser } = useAuth();
  const {addresses} = useAddress();
  
  const editPersonalInfo = () => {
    navigate("edit-personal-info", { apiCurrentUser: apiCurrentUser });
  };

  const showAddress = () => {
    navigate("addresses", {
      apiCurrentUser: apiCurrentUser,
      addresses: addresses
    });
  };

  const changePassWord = () => {
    navigate("change-password", { username: apiCurrentUser.username });
  };

  const callback = () => {
    navigate("login");
  };

  const logOut = () => {
    signOut(callback);
  };

  return (
    <>
    <Header showMenu showNotification title={i18n.t('account')}/>
    <View style={DefaultTheme.container}>
    <View style={{ marginVertical: 10 }} />
      <View style={styles.header}>
        <HeaderText>{apiCurrentUser.username}</HeaderText>
        <Avatar />
      </View>
      <View style={{ marginVertical: 25 }} />
      <Menu
        title={i18n.t('personalInformation')}
        icon={require("../assets/images/user-light.png")}
        iconName={IconName.profile}
        onTap={editPersonalInfo}
      />
      <Menu
        title={i18n.t('language')}
        icon={require("../assets/images/package.png")}
        iconName={IconName.language}
        onTap={() => navigate("language")}
      />
      <Menu
        title={i18n.t('changePassword')}
        icon={require("../assets/images/package.png")}
        iconName={IconName.fingerprint}
        onTap={changePassWord}
      />
      <Menu 
        title={i18n.t('logout')}
        iconName={IconName.signOut}
        onTap={logOut} />
    </View>
    </>
  );
};

const AnonymousView = () => {
  const { navigate } = useNavigation();

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <HeaderText>Account</HeaderText>
      </View>
      <View style={{ marginVertical: 25 }} />
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Image
          source={require("../assets/images/splash.png")}
          style={{ width: 250, height: 250 }}
          resizeMode="contain"
        />
      </View>
      <View style={{ marginVertical: 25 }} />
      <FFAButton
        title="Login"
        onPress={() => navigate("login")}
        type="outline"
      />
      {/* <Button title="Log in" onPress={() => Auth.federatedSignIn()} primary /> */}
      <View style={{ marginVertical: 25 }} />
    </View>
  );
};

export default AccountScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    paddingTop: Platform.OS == "ios" ? 20 : StatusBar.currentHeight + 15,
    paddingHorizontal: 25,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatar: {
    width: 55,
    height: 55,
    borderRadius: 55 / 2,
    resizeMode: "cover",
  },
  menuContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#E5E5E5",
    paddingVertical: 15,
  },
  icon: {
    width: 24,
    height: 24,
  },
  loginButton: {
    borderColor: Colors.borderColor,
    color: Colors.borderColor,
    borderRadius: 10,
  },
});
