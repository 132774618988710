import React, { useEffect, useState } from 'react';
import { StyleSheet } from "react-native";
import { BodyText } from './Text';
import DateTimePicker from '@react-native-community/datetimepicker';
import Colors from "../constants/Colors";
import FlexContainer from './FlexContainer';
import { isDesktop } from "../constants/common";
import Button from './Button';
import { isWeb } from "../constants/common";
import DatePicker from 'react-datepicker';
//Enable This Statement For Web only
//import 'react-datepicker/dist/react-datepicker.css';
const CalenderControl = isWeb ? DatePicker : DateTimePicker;

const Calender = ({
    label,
    onValueChange,
    isEditable,
    isVisible,
    isDisabled,
    bold,
    valueFontSize,
    valueFontColor,
    mode,
    id,
    selectedDate,
    minDate,
    maxDate
}) => {
    const [selectedValue, setSelectedValue] = useState(new Date(selectedDate));
    const [currentDate, setCurrentDate] = useState(selectedDate);
    const [calenderVisible, setVisibilityOfCalender] = useState(false);
    useEffect(() => {
        if (selectedValue) {
            setCurrentDate(selectedValue.toLocaleDateString());
        }
    }, [selectedValue]);
    if (!isVisible) {
        return null;
    }
    const showSideBySide = !isDesktop;
    const onDateSelection = (e, date) => {
        const changedDate = isWeb ? e : date;
        if (changedDate) {
            setSelectedValue(changedDate);
            onValueChange(changedDate);
            setVisibilityOfCalender(false);
        }
    };
    const CustomInput = ({ value, onClick }) => (
        <Button onPress={onClick}
            title={`${value}  `}
            inverted={true}
            btnStyle={[
                styles.calender,
                {
                    marginLeft: showSideBySide ? 5 : 0,
                    minWidth: 180,
                    maxHeight: 32,
                    backgroundColor: 'transparent'
                }
            ]}
            icon={require("../assets/images/calendar-7-32.png")}
            iconEnd />
    );
    return (
        <FlexContainer direction={showSideBySide ? 'row' : 'column'}>
            <BodyText bold={bold} style={[
                showSideBySide ? styles.label : {}
            ]}>{label}</BodyText>
            { isEditable && !isDisabled ? (calenderVisible || isWeb ? <CalenderControl
                    testID={id || 'dateTimePicker'}
                    value={selectedValue}
                    selected={selectedValue}
                    mode={mode || 'date'}
                    is24Hour={true}
                    display="calendar"
                    portalId="root-portal"
                    customInput={<CustomInput />}
                    onSelect={onDateSelection}
                    onChange={onDateSelection}
                    startDate={minDate}
                    endDate={maxDate}
                    minDate={minDate}
                    maxDate={maxDate}
                /> : <Button onPress={() => setVisibilityOfCalender(true)}
                        title={`${currentDate} `}
                        primary
                        btnStyle={[
                            styles.calender,
                            {
                                marginLeft: showSideBySide ? 5 : 0
                            }
                        ]}
                        icon={require("../assets/images/calendar-7-32.png")}
                        iconEnd />) : <BodyText
                size={valueFontSize}
                style={
                    {
                        marginLeft: showSideBySide ? 5 : 0
                    },
                    showSideBySide ? styles.label : {}
                }
                color={valueFontColor}>{currentDate}</BodyText>}
        </FlexContainer>
    );
};

export default Calender;

const styles = StyleSheet.create({
    label: {
        marginTop: 15,
        minWidth: 100,
        paddingTop: 5,
        paddingBottom: 5,
        color: Colors.appColor,
        fontFamily: "playfair-semibold",
    },
    calender: {
        marginTop: 5,
        borderColor: 'black',
        borderWidth: 1,
        borderStyle: 'solid',
        minWidth: 200,
        borderRadius: 5,
        paddingTop: 5,
        paddingRight: 10,
        paddingBottom: 5,
        paddingLeft: 10,
        color: Colors.appColor,
        fontFamily: "playfair-regular",
    }
});